.columns {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: minmax(100px, auto);
}

@media(min-width: 767px) {
  .columns {
    grid-template-columns: repeat(2, 50%);
  }
}

@media(min-width: 1200px) {
  .columns {
    grid-template-columns: repeat(3, 33.333%);
  }
}

.columns.v-center {
  align-items: center;
}

.columns.sys-15-85 {
  grid-template-columns: 0px 15%;
}

.columns.sys-33-66 {
  grid-template-columns: 0px 33%;
}

.columns.sys-66-33 {
  grid-template-columns: 0px 66%;
}

.columns.sys-50-50 {
  grid-template-columns: 50% 50%;
}

@media(min-width: 767px) {
  .columns.sys-2,
  .columns.sys-3,
  .columns.sys-4 {
    grid-template-columns: repeat(2, 50% [col-start]);
  }
}

@media(min-width: 1200px) {
  .columns.sys-3 {
    grid-template-columns: repeat(3, 33.333% [col-start]);
  }

  .columns.sys-4 {
    grid-template-columns: repeat(4, 25% [col-start]);
  }
}

.columns.padded .column > * {
  margin-left: 20px;
  margin-right: 20px;
}

.columns.padded .column:first-child > * {
  margin-left: 0px;
  margin-right: 20px;
}

.columns.padded .column:last-child > * {
  margin-left: 20px;
  margin-right: 0px;
}
