.featured {}

.featured > .columns {
    margin: 0px -10px;
}

.featured .featured--heading {
    margin: 10px 0px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    font-size: 2.75rem;
    font-weight: 300;
    line-height: 1.25em;
}

.featured .featured--items {}

.featured .featured--item {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin: 10px;
    height: calc(100% - 20px);
    min-height: 260px;
    color: #2d2d2d;
    background: #dedede;
    flex: 1 1 calc(50% - 20px);
}

.featured a.featured--item,
.featured .featured--item a {
    text-decoration: none;
    cursor: pointer;
}

.featured .featured--items.featured--large .featured--item {
    flex: 1 1 calc(100% - 20px);
}

.featured .featured--bg-container {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
}

.featured .featured--bg,
.featured .featured--video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #2f2f2f;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.3s ease-in-out;
}

.featured .featured--item:hover .featured--bg,
.featured .featured--item:focus .featured--bg {
    transform: scale(1.05);
    transform-origin: center;
}

@media (prefers-reduced-motion: reduce) {
    .featured .featured--bg,
    .featured .featured--video {
        transition: none;
    }

    .featured .featured--item:hover .featured--bg,
    .featured .featured--item:focus .featured--bg {
        transform: none;
    }
}

.featured .featured--info {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-flow: column;
    justify-content: flex-end;
    padding: 0px;
}

.featured .featured--items.featured--large .featured--info {
    margin-top: 200px;
    width: 50%;
    height: fit-content;
    z-index: 0;
}

@media(min-width: 767px) {
    .featured .featured--items.featured--large .featured--info {
        position: absolute;
        bottom: 0px;
        right: 0px;
        margin-top: 0px;
    }
}

.featured .featured--items.featured--large .featured--item.featured--item-video .featured--info {
    width: 100%;
    justify-content: space-around;
}

.featured .featured--detail {
    display: block;
    margin: 0px;
    padding: 0px 15px;
}

.featured .featured--detail.featured--abstract {
    padding-bottom: 15px;
}

.featured .featured--date {
    padding: 30px 15px 5px 15px;
    font-size: 0.875rem;
}

.featured .featured--title {
    padding: 0px 15px 10px 15px;
    font-family: "PwC ITC Charter", "Charter ITC", georgia, serif;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.25em;
}

.featured .featured--info a.read-more {
    margin: 10px 0px;
    padding: 10px 15px;
}

.featured .featured--info a.read-more:hover,
.featured .featured--info a.read-more:focus {
    text-decoration: underline;
}

.featured .featured--info a.read-more::after {
    content: "";
    display: inline-block;
    margin: 0px 0px 0px 5px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #2d2d2d;
    border-right: 2px solid #2d2d2d;
    transform: rotate(45deg);
    transition: margin 0.3s ease-in-out;
}

.featured .featured--info a.read-more:hover::after,
.featured .featured--info a.read-more:focus::after {
    margin-left: 10px;
}

.featured .featured--item.featured--item-image .featured--detail {
    color: #fff;
    background: rgba(0, 0, 0, 0.75);
}

.featured .featured--items:not(.featured--large) :not(.featured--double-row) .featured--item.featured--item-image .featured--date {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
}

.featured .featured--items:not(.featured--large) :not(.featured--double-row) .featured--item.featured--item-image .featured--title {
    background: rgba(0, 0, 0, 0.75);
}

.featured .featured--items.featured--large .featured--info,
.featured .featured--double-row .featured--item.featured--item-image .featured--info {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    padding: 10px 0px 0px 0px;
    min-height: calc(50% - 20px);
    background: #dedede;
}

.featured .featured--items.featured--large .featured--detail,
.featured .featured--double-row .featured--item.featured--item-image .featured--detail {
    color: #2d2d2d;
    background: #dedede;
}

.featured .featured--double-row .featured--item.featured--item-image .featured--bg-container,
.featured .featured--item.featured--item-video .featured--bg-container {
    bottom: calc(50% - 10px);
}

.featured .featured--item.featured--item-video .featured--bg-container {
    cursor: pointer;
}

/* Colors */
.featured .featured--item.featured--item-white {
    background: #fff;
    color: #2d2d2d;
}

.featured .featured--item.featured--item-grey {
    background: #2d2d2d;
    color: #fff;
}

.featured .featured--item.featured--item-red {
    background: #e0301e;
    color: #fff;
}

.featured .featured--item.featured--item-yellow {
    background: #ffb600;
    color: #2d2d2d;
}

.featured .featured--item.featured--item-pink {
    background: #db536a;
    color: #fff;
}

/* New content label */
.featured .featured--new::before {
    content: "new";

    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;

    display: block;
    padding: 5px 10px;
    font-family: "PwC Helvetica Neue", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 300;
    background: #2d2d2d;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}

.featured .featured--item.featured--item-image.featured--new::before,
.featured .featured--item.featured--item-video.featured--new::before {
    color: #464646;
    background: #ffb600;
}
