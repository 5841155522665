.podcasts {
    display: block;
    padding: 10px;
    border: 1px solid #ddd;
}

.podcasts .podcasts--filter {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr;
    margin: 5px 0px 20px 0px;
}

.podcasts .podcasts--filter input[type="text"] {
    display: block;
    margin: 0px 0px 0px 20px;
    padding: 10px 15px;
	font-size: 0.9rem;
	font-weight: normal;
    border: 1px solid #ddd;
    border-radius: 0px;
}

.podcasts .podcasts--list {
    display: block;
}

.podcasts .podcasts--item {
    display: grid;
    grid-template-columns: 24px auto 4em;
    gap: 10px;
    padding: 5px 10px;
    width: calc(100% - 20px);
    align-items: center;
    border-top: 1px solid #dedede;
    cursor: pointer;
}

.podcasts .podcasts--item.podcasts--item-active,
.podcasts .podcasts--item:hover {
    background: #f0f0f0;
}

.podcasts .podcasts--item > * {
    margin: 0px;
    padding: 5px;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.podcasts .podcasts--heading {
    margin: 10px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 2rem;
	font-weight: normal;
}

.podcasts .podcasts--title {
    font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    font-weight: normal;
}

.podcasts .podcasts--item.podcasts--item-active .podcasts--title {
    font-weight: bold;
}

.podcasts .podcasts--time {
    text-overflow: clip;
    text-align: center;
}

.podcasts .podcasts--tools {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.podcasts .podcasts--button {
    display: block;
    margin: 10px auto;
    padding: 10px 15px;
    color: #2d2d2d;
    background: #f0f0f0;
    text-decoration: none;
    box-shadow: 0px 0px 0px 1px #ddd;
    cursor: pointer;
}

.podcasts .podcasts--button:hover {
    background: #fff;
    box-shadow: 0px 0px 0px 1px #2d2d2d;
}
