.navigation-side-bar {
    position: relative;
    height: 100%;
    z-index: 1;
    color: #2d2d2d;
    background: #dedede;
}   

.navigation-side-bar .next-to-sidebar--items-wrapper {
    position: relative;
    right: 0px;
    bottom: 0px;
    left: 0px;
    max-width: 100vw;
    z-index: 2;
}

.navigation-side-bar .next-to-sidebar--items-wrapper {
    overflow-x: visible;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.navigation-side-bar .next-to-sidebar--items-wrapper::-webkit-scrollbar {
    display: none;
}

.navigation-side-bar .next-to-sidebar--items {
    position: relative;
    display: flex;
    width: fit-content;
    min-width: 100%;
    align-items: stretch;
    justify-content: space-evenly;
    flex-direction: row;
}

.navigation-side-bar .next-to-sidebar--link {
    position: relative;
    padding: 10px 0px;
    width: 80px;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.navigation-side-bar .next-to-sidebar--icon-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    height: 40px;
}

.navigation-side-bar .next-to-sidebar--link.next-to-sidebar--link-current  .next-to-sidebar--icon,
.navigation-side-bar .next-to-sidebar--link:hover .next-to-sidebar--icon,
.navigation-side-bar .next-to-sidebar--link:focus .next-to-sidebar--icon {
    filter: invert(29%) sepia(49%) saturate(5534%) hue-rotate(350deg) brightness(90%) contrast(96%);
}

.navigation-side-bar .next-to-sidebar--icon-wrapper .next-to-sidebar--icon {
    display: block;
    margin: 0px auto;
    max-width: 100%;
}

.navigation-side-bar .next-to-sidebar--text {
    display: block;
    margin: -5px 0px;
    padding: 5px;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.25em;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (min-width: 768px) {
    .navigation-side-bar .next-to-sidebar--text {
        display: none;
    }
}

@media (min-height: 800px) {
    .navigation-side-bar .next-to-sidebar--text {
        display: block;
    }
}

.navigation-side-bar .next-to-sidebar--text.next-to-sidebar--text-smaller {
    font-size: 0.65rem;
}

.navigation-side-bar .next-to-sidebar--link:hover .next-to-sidebar--text {
    overflow: visible;
}

.navigation-side-bar .next-to-sidebar--tooltip {
    position: fixed;
    display: none;
    top: 0px;
    left: 80px;
    margin: 0px 5px;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    font-size: 0.85rem;
    color: #2d2d2d;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 2px #2d2d2d;
    white-space: nowrap;
    transform: translateY(-50%);
}

@media (min-width: 768px) {
    .navigation-side-bar .next-to-sidebar--items-wrapper {
        position: sticky;
        top: 0px;
        width: 70px;
    }

    .navigation-side-bar .next-to-sidebar--items {
        width: 100%;
        flex-direction: column;
    }

    .navigation-side-bar .next-to-sidebar--link {
        width: unset;
    }

    .navigation-side-bar .next-to-sidebar--tooltip:not(:empty) {
        display: block;
    }
}
