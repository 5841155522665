.video-player,
.video-player video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-player.fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;    
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 200;
}

.video-player .button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: #000;
    background: #ddd;
    font-size: 0px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 1;
    transition: background 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .video-player .button {
        transition: none;
    }
}

.video-player:hover .button {
    background: #fff;
}

.video-player.playing .button:not(:hover) {
    opacity: 0;
    filter: invert(1);
    transition: opacity 0.3s ease-in-out;
}

.video-player.playing:hover .button {
    opacity: 1;
}

@keyframes fadeOut {
    0% { opacity: 1; background-color: rgba(255, 255, 255, 0.5); }
    80% { opacity: 1; background-color: rgba(255, 255, 255, 0.5); }
    100% { opacity: 0; }
}
.video-player.playing:not(:hover) .button {
    animation: fadeOut 2s;
}

.video-player .button.sound {
    display: none;
    right: 80px;
}

.video-player .button.fullscreen {
    display: none;
    right: 160px;
}

.video-player.playing .button.sound,
.video-player.playing .button.fullscreen {
    display: block;
}

.video-player .button > img {
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    object-fit: cover;
}
