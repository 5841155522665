.footer {
    color: #fff;
    background: #424242;
}

.footer .footer--content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 20px;
    min-height: 140px;
}

@media (min-width: 768px) {
    .footer .footer--content {
        padding: 40px 100px;
    }
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.footer .footer--main-links,
.footer .footer--disclaimer-links {
    display: block;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.footer .footer--main-links a,
.footer .footer--disclaimer-links a {
    display: block;
    padding: 5px 0px;
}

.footer .footer--main-links {
    font-size: 1.25rem;
}

@media (min-width: 768px) {
    .footer .footer--main-links,
    .footer .footer--disclaimer-links {
        display: block;
        display: flex;
        flex-flow: row;
        gap: 20px;
    }
}

.footer .footer--disclaimer {
    margin: 10px 0px;
    padding: 5px 0px;
    border-top: 2px solid #ddd;
}

.footer .footer--disclaimer p {
    margin: 0px;
    padding: 0px;
}