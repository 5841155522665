/* .contact {} */

/* Preview */
.contact .contact--preview-notice {
    display: block;
    margin: 20px 0px;
	padding: 20px;
    border: 4px solid #e0301e;
}

.contact .contact--hero {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    margin: 20px 0px;
    min-height: 300px;
}

.contact .contact--hero-content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.contact .contact--heading {
	margin: 0px 0px 20px 0px;
    font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 2.75rem;
	font-weight: normal;
}

.contact .contact--profile-image-wrapper {
    margin: 0px;
    overflow: hidden;
}

.contact .contact--profile-image-wrapper .contact--profile-image {
    display: block;
    margin: 0px auto;
    max-width: 100%;
}

.contact dl.contact--details {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 10px 20% 10px 0px;
    border-top: 1px solid #ddd;
}

.contact dl.contact--details dt,
.contact dl.contact--details dd {
    display: block;
    margin: 0px;
    padding: 10px 15px 10px 0px;
    border-bottom: 1px solid #ddd;
}

.contact dl.contact--details dd a {
    color: inherit;
    text-decoration: underline;
}

.contact dl.contact--details dd a:hover {
    color: #28f;
}

.contact .contact--social-links {
    display: flex;
    flex-flow: row;
    justify-self: start;
    margin: 0px;
    padding: 0px;
    gap: 10px;
}

.contact .contact--social-links a {
    display: block;
    width: 36px;
    height: 36px;
}

.contact .contact--social-links a img {
    display: block;
    margin: 0px auto;
    max-width: 100%;
    max-height: 100%;
}

.contact .contact--social-links a:hover img,
.contact .contact--social-links a:focus img {
    filter: invert(1) brightness(0.3) sepia(100%) saturate(10) hue-rotate(320deg);
}

.contact .contact--social-links a.twitter:hover img,
.contact .contact--social-links a.twitter:focus img {
    filter: invert(1) brightness(0.4) sepia(100%) saturate(10) hue-rotate(180deg);
}

.contact .contact--social-links a.linkedin:hover img,
.contact .contact--social-links a.linkedin:focus img {
    filter: invert(1) brightness(0.4) sepia(100%) saturate(10) hue-rotate(180deg);
}

.contact .contact--social-links a.facebook:hover img,
.contact .contact--social-links a.facebook:focus img {
    filter: invert(1) brightness(0.3) sepia(100%) saturate(10) hue-rotate(180deg);
}

.contact .contact--body {
    margin: 20px 0px;
	padding: 0px;
}

.contact .contact--body > :first-child {
	margin-top: 0px;
}
