/* Preview */
.podcast .podcast--preview-notice {
    display: block;
    margin: 20px 0px;
	padding: 20px;
    border: 4px solid #e0301e;
}

.podcast-preview .podcast-preview--image-wrapper {
    position: relative;
    display: block;
    padding-top: 56.25%;
    width: 100%;
    height: 0px;
    overflow: hidden;
}

.podcast-preview .podcast-preview--image-wrapper .podcast-preview--image {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: cover;
}

.podcast-preview .podcast-preview--image-wrapper + .podcast-preview--injected-player-wrapper {
    margin: 40px 0px;
}

.podcast-preview .podcast-preview--content {
    margin-top: 40px;
}

.podcast-preview .podcast-preview--details {
    float: right;
}

.podcast-preview .podcast-preview--description {
    margin: 20px 0px;
	padding: 0px;
}

.podcast-preview .podcast-preview--description > :first-child {
	margin-top: 0px;
}
