
.themed-player {
  position: relative;
  margin: 0px;
  padding: 0px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}

.themed-player.themed-player--no-upper-rounding {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.themed-player .themed-player--layout {
  position: relative;
  display: grid;
  grid-template-columns: min-content 8fr min-content 8fr;
  justify-content: stretch;
  align-content: center;
  padding: 5px;
  border-radius: inherit;
  z-index: 1;
}

@media(min-width: 480px) {
  .themed-player .themed-player--layout {
    grid-template-columns: min-content 8fr min-content 4fr;
  }
}

@media(min-width: 767px) {
  .themed-player .themed-player--layout {
    grid-template-columns: min-content 8fr min-content 3fr;
  }
}

.themed-player .themed-player--controls {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 10px;
}

.themed-player .themed-player--range-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  padding: 5px 0px;
}

.themed-player input[type="range"] {
  margin: 0px;
  padding: 3px 0px;
  height: 0px;
  width: 100%;
  border-radius: 4px;
  background: #444;
  transition: background 0.2s ease;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* The same definition needs to be duplicated twice as Chrome is ignoring this rule if combined into one :( */
.themed-player input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  margin: 0px;
  padding: 8px;
  width: 0px;
  height: 0px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.themed-player input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0px;
  padding: 8px;
  width: 0px;
  height: 0px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.themed-player .themed-player--button {
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 2em;
  height: 2em;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

.themed-player .themed-player--button svg {
  margin: auto;
  width: 100%;
  max-height: 100%;
}

.themed-player .themed-player--button svg path {
  fill: #000;
}

.themed-player .themed-player--button:hover,
.themed-player .themed-player--button:focus {
  opacity: 1;
}

.themed-player .themed-player--time-remaining {
  display: block;
  margin: 0px 0px 0.15em 0px;
  font-size: 0.9rem;
  line-height: 1em;
}

.themed-player .themed-player--volume {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.themed-player .themed-player--button-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  font-size: 0.8rem;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  opacity: 0;
  z-index: 1;
  transform: translateX(-50%) translateY(65%);
  transition: all 0.3s ease-in-out;
}

.themed-player .themed-player--button:hover .themed-player--button-tooltip,
.themed-player .themed-player--range-wrapper:hover .themed-player--button-tooltip {
  opacity: 1;
}

.themed-player .themed-player--image-wrapper {
  position: relative;
  display: block;
  aspect-ratio: 16 / 9;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.themed-player .themed-player--image-wrapper .themed-player--image {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.themed-player .themed-player--image-wrapper .themed-player--image:hover {
  transform: scale(1.05);
}

.themed-player:not(.themed-player--playing) .themed-player--image-wrapper {
  filter: sepia(0.5);
  opacity: 0.9;
}

/* BG colors */
@keyframes colorChange {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

.themed-player .themed-player--color-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient(45deg, #c8eeff 20%, transparent 140%) no-repeat;
  border-radius: inherit;
}

.themed-player.themed-player--playing .themed-player--color-backdrop {
  animation: colorChange 20s alternate infinite;
}

.themed-player.themed-player--color-white .themed-player--color-backdrop {
  background: #fff;
  animation: none;
}
