.collection {}

.collection .collection--heading {
    margin: 20px 0px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 2rem;
	font-weight: normal;
}

.collection .collection--count {
    display: block;
    margin: 10px 0px;
    font-size: 1rem;
    color: #888;
}

.collection .collection--cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

@media(min-width: 767px) {
    .collection .collection--cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
  
@media(min-width: 1200px) {
    .collection .collection--cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .collection.collection--fullWidth .collection--cards {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.collection .collection--card {
    margin: 0px 0px 20px 0px;
    line-height: 1.25rem;
    color: #2d2d2d;
    text-decoration: none;
    cursor: pointer;
}

.collection .collection--image-wrapper {
    margin-bottom: 10px;
    width: 100%;
    height: 200px;
    background: #2d2d2d;
    overflow: hidden;
}

.collection .collection--image-wrapper .collection--image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    transition: all 0.3s ease-in-out;
    transform-origin: center;
}

.collection .collection--card:hover .collection--image,
.collection .collection--card:focus .collection--image {
    transform: scale(1.05);
}

@media (prefers-reduced-motion: reduce) {
    .collection .collection--card:hover .collection--image,
    .collection .collection--card:focus .collection--image {
        transform: none;
    }
}

.collection .collection--date-wrapper {
    margin: 10px 0px;
    text-align: center;
}

.collection .collection--details {
    display: grid;
    grid-template-columns: 3.5rem auto;
    grid-gap: 20px;
}

.collection .collection--info {
    margin: 10px 0px;
}

.collection .collection--title {
    margin: 5px 5px 10px 0px;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5rem;
    overflow: hidden;
}

.collection .collection--text {
    margin-bottom: 10px;
    line-height: 1.375rem;
    overflow: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.collection .collection--text:empty::before,
.collection .collection--text:empty::after {
    content: "";
    display: block;
    margin-bottom: 6px;
    height: 1.375rem;
    background: rgba(0, 0, 0, 0.05);
}

.collection .collection--card:hover .collection--text:empty::before,
.collection .collection--card:focus .collection--text:empty::before,
.collection .collection--card:hover .collection--text:empty::after,
.collection .collection--card:focus .collection--text:empty::after {
    background: linear-gradient(-45deg, #e75, #e37, #2ad, #2da);
    background-size: 400% 400%;
	animation: gradient 5s ease infinite;
}

.collection .collection--text:empty::before {
    width: 100%;
}

.collection .collection--text:empty::after {
    width: 75%;
}

.collection .collection--tools {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.collection .collection--tools.collection--tools-align-center {
    justify-content: center;
}

.collection .collection--tools .collection--button {
    display: block;
    margin: 10px;
    padding: 10px 15px;
    color: #2d2d2d;
    background: transparent;
    font-weight: 300;
    text-decoration: none;
    text-align: center;
    font-size: 1.25rem;
    border: 1px solid #2d2d2d;
    transition: background 0.25s ease;
    cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
    .collection .collection--tools .collection--button {
        transition: none;
    }
}

.collection .collection--tools .collection--button:hover,
.collection .collection--tools .collection--button:focus {
    background: #fff;
}

.collection .collection--tools .collection--load-more {
    display: block;
    margin: 10px 0px;
    width: fit-content;
    font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    font-weight: 300;
    text-decoration: none;
    text-align: center;
    font-size: 1.5rem;
    color: inherit;
    border-bottom: 2px solid #e0301e;
    cursor: pointer;
}

.collection .collection--tools .collection--load-more:hover,
.collection .collection--tools .collection--load-more:focus {
    color: #e0301e;
}
