.related-content:not(.related-content--no-padding) {
    padding: 20px;
}

.related-content .related-content--heading {
    margin: 20px 0px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 2.5rem;
	font-weight: normal;
}

.related-content.related-content--no-padding .related-content--heading {
    font-size: 2rem;
}

.related-content .related-content--row {
    display: grid;
    margin: 10px 0px;
    grid-template-columns: 1fr;
    gap: 20px;
}

@media(min-width: 480px) {
    .related-content .related-content--row {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 768px) {
    .related-content .related-content--row {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.related-content .related-content--item {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    color: #fff;
    background: #2d2d2d;
    text-decoration: none;
    aspect-ratio: 2 / 1;
}

.related-content .related-content--image-wrapper,
.related-content .related-content--image {
    display: block;
    width: 100%;
    height: 100%;
    background: #2d2d2d;
    overflow: hidden;
}

.related-content .related-content--image {
    background-size: cover;
    background-position: center;
    transition: all 0.25s ease-in-out;
    transform-origin: center;
}

.related-content .related-content--item:hover .related-content--image,
.related-content .related-content--item:focus .related-content--image {
    transform: scale(1.05);
}

@media (prefers-reduced-motion: reduce) {
    .related-content .related-content--item:hover .related-content--image,
    .related-content .related-content--item:focus .related-content--image {
        transform: none;
    }
}

.related-content .related-content--details {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    padding: 20px;
    overflow: hidden;
}

.related-content .related-content--date {
    font-size: 0.9rem;
}

.related-content .related-content--title {
    display: block;
    max-height: 400px;
    font-family: "PwC ITC Charter", Georgia, serif;
    font-size: 1.3rem;
    line-height: 1.375rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.related-content .related-content--item:hover .related-content--title,
.related-content .related-content--item:focus .related-content--title {
    text-decoration: underline;
}