.podcasts-page .podcasts-page--title {
    order: 2;
}

.podcasts-page .podcasts-page--description-column {
    order: 1;
}

.podcasts-page .podcasts-page--content-container {
    order: 3;
}

@media (min-width: 992px) {
    .podcasts-page .podcasts-page--title {
        order: 1;
    }

    .podcasts-page .podcasts-page--description-column {
        order: 3;
    }

    .podcasts-page .podcasts-page--content-container {
        order: 2;
    }
}

.podcasts-page .podcasts-page--description {
    font-size: 1.25rem;
    line-height: 1.5em;
}

.podcasts-page .podcasts-page--description p:first-child {
    margin-top: 0px;
}

.podcasts-page .podcasts-page--content {
    margin: 5px 0px;
    transition: opacity 0.3s ease-in-out;
}

.podcasts-page .podcasts-page--title {
    font-family: "PwC Helvetica Neue Light", "Helvetica Neue Light", helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: 2.25rem;
}

@media (min-width: 992px) {
    .podcasts-page .podcasts-page--title {
        grid-column-end: span 2;
    }
}