
.story {
	font-size: 20px; /* Story base font size */
}

/* Preview */
.story .story--preview-notice {
    display: block;
    margin: 20px 0px;
	padding: 20px;
    border: 4px solid #E0301E;
}

.story .story--preview-notice::before {
    content: "";
	display: block;
}

.story .story--preview-notice strong {
    color: #E0301E;
}

/* Story parts */
.story .story--hero-title {
	position: relative;
	color: #fff;
	background: #db536a;
}

.story .story--hero-title::after {
	content: "";
	display: block;
	height: 140px;
}

.story .story--hero-title .container::after {
	clear: none;
}

.story .story--hero-title .story--hero-content {
	padding: 60px 0px;
}

.story .story--hero-title a.story--category {
	margin: 0px 10px 0px 0px;
	font-size: 1.25rem;
	color: inherit;
	text-decoration: none;
}

.story .story--hero-title h1.story--heading {
	margin: 14px 0px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 3.375rem;
	line-height: 1.25em;
	font-weight: normal;
}

.story .story--hero-title .story--date {
	font-size: 1.25rem;
	font-weight: 700;
	text-transform: uppercase;
}

.story .story--hero-content + .story--hero-image {
	display: block;
	margin: 20px 0px 0px 0px;
	width: 100%;
	box-shadow: 0px 0px 0px 20px #fff;
	background-position: top center;
	background-size: cover;
	overflow: hidden;
	float: left;
	aspect-ratio: 16 / 9;
}

.story .story--content {
	clear: both;
}

.story .story--content .story--body {
	display: block;
	margin: 60px 0px 100px 0px;
	padding: 0px;
	width: 100%;
	color: #2d2d2d;
	background: #fff;
	line-height: 1.75em;
}

@media (min-width: 768px) {
    .story .story--content .story--body {
		margin-left: 10%;
		margin-right: 10%;
		width: auto;
	}
}

.story .story--content .story--body > :first-child {
	margin-top: 0px;
}
