.standard-date {
    display: block;
    line-height: 1.75rem;
}

.standard-date.padded {
    padding: 10px;
}

.standard-date .year {
    font-size: 1.25rem;
    font-variant-numeric: tabular-nums;
}

.standard-date .month {
    font-size: 1.25rem;
    text-transform: capitalize;
}

.standard-date .day {
    font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    font-size: 2.75rem;
    line-height: 1em;
    font-variant-numeric: tabular-nums;
}