.hero-section {
    display: block;
    overflow: hidden;
}

.hero-section .hero-section--heading {
    position: relative;
    margin: 20px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 2.5rem;
	font-weight: normal;
    z-index: 1;
}

.hero-section .hero-section--content {
    position: relative;
    display: flex;
    margin: 0px;
    padding: 15px;
    flex-flow: column;
    justify-content: flex-end;
    min-height: 240px;
    background-color: #2d2d2d;
    color: #fff;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    transition: all 0.3s ease;
}

@media (prefers-reduced-motion: reduce) {
    .hero-section .hero-section--content {
        transition: none;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hero-section .hero-section--content .hero-section--hero-image {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    font-size: 0px;
    object-fit: cover;
    overflow: hidden;
    animation: fadein 0.3s;
    z-index: 0;
}

@media (prefers-reduced-motion: reduce) {
    .hero-section .hero-section--content .hero-section--hero-image {
        animation: none;
    }
}

.hero-section.hero-section--background-default .hero-section--content {
    background-color: #db536a;
    color: #fff;
}

.hero-section.hero-section--background-yellow .hero-section--content {
    background-color: #ffb600;
    color: #2d2d2d;
}

.hero-section .hero-section--content.hero-section--text-light {
    color: #fff;
}

.hero-section .hero-section--content.hero-section--text-dark {
    color: #2d2d2d;
}
