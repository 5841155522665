.login-page {
    margin: 20px auto;
    min-height: 100%;
}

@media (min-width: 992px) {
    .login-page {
        max-width: 480px;
    }
}

.login-page form .login-form--group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: center;
    margin: 20px auto;
}

@media (min-width: 768px) {
    .login-page form .login-form--group {
        grid-template-columns: 1fr 3fr;
    }
}

.login-page form .login-form--submit {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 20px;
}

.login-page form input[type="text"],
.login-page form input[type="password"] {
    display: block;
    padding: 10px;
}

.login-page form input[type="submit"] {
    display: block;
    padding: 10px;
    cursor: pointer;
}

.login-page .login-page--btn {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background: #ddd;
    border: 1px solid #ddd;
    cursor: pointer;
}

.login-page .login-page--btn:hover {
    background: #fff;
}