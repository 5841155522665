.loader.running {
    padding: 20px 0px 80px 0px;
}

.loader.absolute {
    position: absolute;
    width: 100%;
}

.loader .animation {
    position: relative;
    display: none;
    margin: 0px auto;
    width: 100px;
    height: 100px;
    font-size: 0px;
    line-height: 0px;
}

.loader.running .animation {
    display: block;
}

.loader .animation > div {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 50%;
}

.loader .animation > div:nth-child(1) {
    margin: 4px;
    border: 10px solid transparent;
    border-top: 10px solid #444; /* Dark grey */
}

.loader.running .animation > div:nth-child(1) {
    animation: spin-regular 1s linear infinite;
}

@keyframes spin-regular {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader .animation > div:nth-child(2) {
    border: 4px solid transparent;
    border-bottom: 4px solid #e0301e; /* Red */
}

.loader.running .animation > div:nth-child(2) {
    animation: spin-inverse 2s linear infinite;
}

@keyframes spin-inverse {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
}

.loader .info {
    display: none;
    margin: 10px;
    text-align: center;
}

.loader.running .info {
    display: block;
}
