.site-quote {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    margin-top: 60px;
}

.site-quote .site-quote--title {
    font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    font-size: 2rem;
    font-weight: 300;
}

.site-quote .site-quote--content {
    padding: 0px 20px;
    border-left: 4px solid #e0301e;
}

.site-quote .site-quote--text {
    margin: 0px 0px 15px 0px;
    font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    font-size: 2.75rem;
    font-weight: 300;
    line-height: 1em;
}

.site-quote .site-quote--author {
    font-family: "PwC Helvetica Neue", "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1rem;
}