.site-header {
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 1);
    z-index: 100;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.site-header a {
    color: inherit;
    text-decoration: none;
}

.site-header .site-header--content {
    display: grid;
    grid-template-columns: auto;
    column-gap: 0px;
    align-items: center;
    margin: 0px 20px;
}

@media(min-width: 480px) {
    .site-header .site-header--content {
        grid-template-columns: 1fr auto;
    }
}

@media (min-width: 767px) {
    .site-header .site-header--content {
        grid-template-columns: auto 1fr auto;
        column-gap: 15px;
        margin: 0px 100px;
        height: 86px;
    }
}

.site-header .site-header--brand {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 10px 0px;
    max-width: 300px;
    color: #666;
    font-family: "PwC ITC Charter", "Charter ITC", georgia, serif;
    font-size: 1rem;
    line-height: 1.3em;
}

.site-header .site-header--brand .site-header--divider {
    display: block;
    margin: 0px 16px 4px 4px;
    height: 1rem;
    border-left: 1px solid #2d2d2d;
}

.site-header .site-header--brand .site-header--site-name {
    display: block;
    padding-bottom: 4px;
}

.site-header .site-header--brand .site-header--site-name:hover,
.site-header .site-header--brand .site-header--site-name:focus {
    border-bottom: 1px solid #e0301e;
    padding-bottom: 3px;
    text-decoration: none;
}

.site-header .site-header--tools {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

@media(min-width: 480px) {
    .site-header .site-header--tools {
        order: 2;
        grid-column-end: span 2;
    }
    
    .site-header .site-header--tools + .site-header--tools {
        order: 1;
        grid-column-end: span 1;
    }
}

@media(min-width: 768px) {
    .site-header .site-header--tools {
        gap: 20px;
        order: 1;
        grid-column-end: span 1;
    }

    .site-header .site-header--tools + .site-header--tools {
        order: 2;
        grid-column-end: span 1;
    }
}

.site-header .site-header--tool {
    display: flex;
    align-items: center;
    justify-content: center;
}

.site-header .site-header--tool-button {
    display: block;
    width: 28px;
    height: 28px;
    cursor: pointer;
    overflow: hidden;
}

.site-header .site-header--tool-icon {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease;
}

.site-header .site-header--tool-button:hover .site-header--tool-icon,
.site-header .site-header--tool-button:focus .site-header--tool-icon {
    transform: translateY(-100%);
}

.site-header .site-header--tool-icon > * {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.site-header .site-header--tool-icon .site-header--tool-icon-active {
    filter: invert(1) brightness(0.4) sepia(100%) saturate(100) hue-rotate(30deg);
}

.site-header .site-header--tool-icon .site-header--tool-icon-search {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath fill='%23404041' d='M46,44.27l-13-13A16.73,16.73,0,1,0,31.33,33l13,13A1.19,1.19,0,0,0,46,44.27ZM6,20.4A14.32,14.32,0,1,1,20.36,34.72,14.34,14.34,0,0,1,6,20.4Z'/%3E%3C/svg%3E%0A");
}

.site-header .site-header--tool-icon .site-header--tool-icon-share {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath fill='%23404041' d='M38.34,32.13a5.81,5.81,0,0,0-4.82,2.57L17.28,26.46a5.63,5.63,0,0,0,0-3l16.1-8.17a5.93,5.93,0,1,0-.89-2.22L16.16,21.33a5.81,5.81,0,1,0,.06,7.27l16.4,8.32a5.81,5.81,0,1,0,5.71-4.79Zm-.15-23.5a3.42,3.42,0,1,1-3.43,3.43A3.43,3.43,0,0,1,38.19,8.63ZM11.66,28.43A3.42,3.42,0,1,1,15.09,25,3.43,3.43,0,0,1,11.66,28.43ZM38.34,41.37a3.43,3.43,0,1,1,3.43-3.43A3.43,3.43,0,0,1,38.34,41.37Z'/%3E%3C/svg%3E");
}

.site-header .site-header--tool-icon .site-header--tool-icon-user {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18.5c-4.694 0-8.5-3.806-8.5-8.5S7.306 3.5 12 3.5s8.5 3.806 8.5 8.5-3.806 8.5-8.5 8.5zm0-8c-3.038 0-5.5 1.728-5.5 3.5s2.462 3.5 5.5 3.5 5.5-1.728 5.5-3.5-2.462-3.5-5.5-3.5zm0-.5c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z'/%3E%3C/svg%3E%0A");
}

.site-header .site-header--tool-expand {
    display: block;
    width: 0px;
    overflow: hidden;
    transition: all 0.5s ease;
}

.site-header .site-header--tool-padded-content {
    margin: 10px;
}

.site-header .site-header--tool-expand.site-header--tool-expand-open {
    width: auto;
}

.site-header .site-header--tool-expand.site-header--tool-expand-fullview {
    width: auto;
    transition: none;
}

.site-header .site-header--tool-search-box {
    display: block;
    padding: 10px;
    font-family: "PwC ITC Charter", "Charter ITC", georgia, serif;
    font-size: 0.875rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid #dedede;
    border-radius: 0;
    box-shadow: none;
}

.site-header .site-header--tool-user span {
    padding: 10px;
    font-family: "PwC ITC Charter", "Charter ITC", georgia, serif;
    font-size: 1rem;
    line-height: 1.3em;
}

.site-header .site-header--tool-user a {
    font-weight: bold;
    white-space: nowrap;
}

.site-header .site-header--popover {
    position: absolute;
    display: block;
    top: 100%;
    right: 10px;
    margin: 10px;
    padding: 5px 10px;
    width: fit-content;
    color: #fff;
    background: #2d2d2d;
    box-shadow: 0px 0px 2px 1px #fff;
    border-radius: 4px;
    visibility: hidden;
    white-space: nowrap;
    opacity: 0;
    z-index: 100;
    transition: opacity 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
    .site-header .site-header--popover {
        transition: none;
    }
}

.site-header .site-header--popover.site-header--popover-visible {
    visibility: visible;
    opacity: 1;
}

.site-header .site-header--banner {
    display: block;
    margin: 0px;
    padding: 10px 15px;
    font-family: "PwC ITC Charter", "Charter ITC", Georgia, serif;
    font-size: 1rem;
    color: #fff;
    background: #2d2d2d;
    border-left: 3px solid #e0301e;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .site-header .site-header--banner {
        transition: none;
    }
}

@media(min-width: 768px) {
    .site-header .site-header--banner {
        position: absolute;
        top: 100%;
        right: 0px;
    }
}

.site-header .site-header--banner:hover,
.site-header .site-header--banner:focus {
    color: #fff;
    background: #111;
    border-left: 3px solid #ffb600;
}

.site-header .site-header--banner .site-header--banner-text::after {
    content: "";
    display: inline-block;
    margin: 0px 5px 0px 10px;
    width: 8px;
    height: 1em;
    background: transparent url("data:image/svg+xml, %3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L1 13' stroke='white'/%3E%3C/svg%3E%0A") no-repeat center;
    background-size: cover;
    vertical-align: middle;
    transition: margin 0.2s;
}

.site-header .site-header--banner:hover .site-header--banner-text::after,
.site-header .site-header--banner:focus .site-header--banner-text::after {
    margin: 0px 0px 0px 15px;
}