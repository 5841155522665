.featured-contact {
    display: grid;
    grid-template-columns: 1fr;
    margin: 20px 0px;
    color: #2d2d2d;
    background: #eb8c00;
    min-height: 250px;
}

.featured-contact.featured-contact--full-width {
    background: transparent;
    padding: 50px 20px;
    min-height: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media(min-width: 767px) {
    .featured-contact {
        grid-template-columns: 20% auto 20%;
    }

    .featured-contact.featured-contact--full-width {
        grid-template-columns: 1fr;
    }
}

.featured-contact .featured-contact--image-wrapper {
    display: block;
    min-height: 200px;
}

.featured-contact .featured-contact--image-wrapper .featured-contact--image {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.featured-contact .featured-contact--content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 20px;
}

.featured-contact .featured-contact--name {
    margin: 0px 0px 10px 0px;
    font-size: 1.25rem;
    font-weight: bold;
}

.featured-contact .featured-contact--job-title {
    margin: 0px 0px 10px 0px;
    font-size: 1.25rem;
    font-weight: normal;
}

.featured-contact .featured-contact--quote {
    margin: 10px 0px;
    padding: 0px;
    font-size: 1.15rem;
    font-weight: normal;
}

.featured-contact.featured-contact--full-width .featured-contact--quote {
    font-size: 1.25rem;
    line-height: 1.5em;
}

.featured-contact .featured-contact--quote::before {
    content: "“"
}

.featured-contact .featured-contact--quote::after {
    content: "”"
}

.featured-contact .featured-contact--read-more {
    color: inherit;
    font-size: 1.15rem;
    font-weight: normal;
    text-decoration: none;
}

.featured-contact .featured-contact--read-more::after {
    content: "";
    display: inline-block;
    margin: 0px 0px 0px 10px;
    padding: 0.25rem;
    width: 0px;
    height: 0px;
    border-top: 1px solid #2d2d2d;
    border-right: 1px solid #2d2d2d;
    transform: rotate(45deg);
    vertical-align: middle;
    transition: margin 0.2s ease;
}

.featured-contact .featured-contact--read-more:hover::after {
    margin-left: 15px;
}

.featured-contact .featured-contact--heading {
    margin: 20px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
    color: #fff;
    font-size: 2.25rem;
	font-weight: normal;
    order: -1;
}

@media(min-width: 767px) {
    .featured-contact .featured-contact--heading {
        order: unset;
    }
}