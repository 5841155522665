/* Instagra feed plugin styles */

.instagram-feed {
    display: block;
    margin: 0px 0px 40px 0px;
    font-family: "PwC Helvetica Neue", "Helvetica Neue", helvetica, arial, sans-serif;
}

.instagram-feed .instagram-feed--heading {
    margin: 20px 0px;
	font-family: "PwC Helvetica Neue Light", Arial, Sans-Serif;
	font-size: 2rem;
	font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.instagram-feed .instagram-feed--heading a {
    color: inherit;
    text-decoration: inherit;
}

.instagram-feed .instagram-feed--heading a:hover {
    text-decoration: underline;
}

.instagram-feed .instagram-feed--posts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    font-size: 1rem;
    line-height: 1.25em;
}

.instagram-feed .instagram-feed--posts.instagram-feed--posts-scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 70vh;
}

.instagram-feed .instagram-feed--post {
    display: block;
    font-size: 0.9rem;
    border: 1px solid #efefef;
}

.instagram-feed .instagram-feed--post-info {
    display: block;
    margin: 5px;
    font-size: 0.85rem;
    line-height: 1em;
}

.instagram-feed .instagram-feed--post-info a {
    color: inherit;
    text-decoration: inherit;
}

.instagram-feed .instagram-feed--post-info-source {
    font-weight: bold;
}

.instagram-feed .instagram-feed--post-info-timestamp {
    opacity: 0.5;
    font-size: 0.7rem;
}

.instagram-feed .instagram-feed--post-info-source img {
    display: inline-block;
    margin: 0px 10px 0px 0px;
    height: 0.85rem;
    filter: grayscale(0.5);
}

.instagram-feed .instagram-feed--media {
    display: block;
    margin: 0px auto;
    background: #000;
    overflow: hidden;
}

.instagram-feed .instagram-feed--media img,
.instagram-feed .instagram-feed--media video {
    display: block;
    margin: auto;
    max-width: calc(100% + 1px);
    max-height: 350px;
    transform: scale(1);
    transform-origin: center;
    transition: all 0.3s ease-in-out;
}

.instagram-feed .instagram-feed--media:hover img,
.instagram-feed .instagram-feed--media:hover video,
.instagram-feed .instagram-feed--media:focus img,
.instagram-feed .instagram-feed--media:focus video {
    transform: scale(1.05);
}

@media (prefers-reduced-motion: reduce) {
    .instagram-feed .instagram-feed--media:hover img,
    .instagram-feed .instagram-feed--media:focus img,
    .instagram-feed .instagram-feed--media:hover video,
    .instagram-feed .instagram-feed--media:focus video {
        transform: none;
    }
}

.instagram-feed .instagram-feed--caption {
    margin: 5px;
}

.instagram-feed .instagram-feed--caption pre {
    white-space: pre-wrap;
    font-family: inherit;
}

.instagram-feed .instagram-feed--view-on-instagram {
    display: block;
    margin: 10px 0px 10px auto;
    width: fit-content;
    font-size: 0.75rem;
    color: #2b7bb9;
    text-decoration: none;
}

.instagram-feed .instagram-feed--view-on-instagram:hover {
    color: #3b94d9;
}