.page-layout {
    display: block;
}

.page-layout .page-layout--nav {
    position: fixed;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    z-index: 10;
}

.page-layout .page-layout--content {
    overflow: hidden;
    background: #fff;
}

.page-layout .page-layout--footer:empty {
    display: none;
}

@media (min-width: 768px) {
    .page-layout {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: 1fr;
        min-height: 100vh;
    }

    .page-layout .page-layout--nav {
        position: relative;
        width: auto;
        overflow: visible;
        order: 1;
    }

    .page-layout .page-layout--content {
        order: 2;
    }

    .page-layout .page-layout--footer {
        order: 3;
        grid-column-end: span 2;
        height: fit-content;
    }
}
