body {
    margin: 0px;
    padding: 0px;
    font-family: "PwC Helvetica Neue", "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 16px;
    color: #2d2d2d;
    background: #f2f2f2;
}

body.no-scroll {
    overflow: hidden;
}

section:first-of-type:not(.section--no-padding) {
    padding-top: 60px;
}

section:not(.section--no-padding) {
    padding: 40px 0px;
}

/* Container */
.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .container {
        width: 640px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 860px;
        max-width: calc(100% - 30px);
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1024px;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1280px;
    }
}

/* Clear floats */
.container:before,
.container:after {
    content: " ";
    display: table;
}

.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}
